
import Donut from 'common/loading/Donut.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingOr',
  components: { Donut },
  props: {
    loading: Boolean,
  },
})
