
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import ToggleFollowDialog from '@/modules/weTradeCup/views/ToggleFollowDialog.vue'
import { Master, Order, readMasterOrders } from '@/modules/weTradeCup/weTradeCup.api'
import LoadingOr from '@/provider/LoadingOr.vue'
import { YesOrNo } from '@/types'
import { defineComponent, shallowRef, computed } from 'vue'
import { translate } from '@/i18n'
import state from '@/state'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import SymbolLink from '@/provider/SymbolLink.vue'

export default defineComponent({
  name: 'TheMasterPositions',
  components: { LoadingOr, Icon, Pic, SymbolLink },
  props: {
    master: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const master = props.master as Master
    const haveMore = shallowRef(master.orderNumbers > 2)
    const orders = shallowRef<Order[]>(master.orderInfoModels)
    const isFollow = shallowRef(master.follow === YesOrNo.YES)

    const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    const loaded = (resp: Master) => {
      haveMore.value = false
      orders.value = resp.orderInfoModels
    }

    const [load, loading] = useRequest(readMasterOrders)

    const toggleFollow = () => {
      if (!isPrime.value) {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'cup_27',
        })
        return false
      }
      openDialog(ToggleFollowDialog, {
        isFollow: isFollow.value,
        masterId: master.userId,
        masterName: master.name,
        onConfirm () {
          isFollow.value = !isFollow.value
        },
      })
    }

    const loadMore = () => load({
      masterId: props.master.userId,
    }).then(loaded)

    return {
      orders,
      haveMore,
      loading,
      isFollow,
      toggleFollow,
      loadMore,
      translate,
    }
  },
})
