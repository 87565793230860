
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePrizeCategory',
  components: { Pic },
  props: {},
  setup () {
    return {}
  },
})
