
import Button from '@/components/Button.vue'
import { follow, unfollow } from '@/modules/weTradeCup/weTradeCup.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'ToggleFollowDialog',
  components: { Button, DialogTemplate },
  props: {
    isFollow: Boolean,
    masterName: {
      type: String,
      required: true,
    },
    masterId: {
      type: Number,
      required: true,
    },
  },
  setup (props, ctx) {
    const progress = shallowRef(false)

    const toggle = () => {
      progress.value = true
      ;(props.isFollow ? unfollow : follow)({ masterId: props.masterId })
        .then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
        .finally(() => {
          progress.value = false
        })
    }

    return {
      toggle,
      progress,
    }
  },
})
