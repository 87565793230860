
import ColorText from '@/components/ColorText.vue'
import Nullable from '@/components/Nullable.vue'
import Holder from '@/provider/Holder.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import TheMasterPositions from '@/modules/weTradeCup/components/TheMasterPositions.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { promptOpenOperation, cupDateFormat } from '@/modules/weTradeCup/weTradeCup'
import { Master, readMasters } from '@/modules/weTradeCup/weTradeCup.api'
import { defineComponent, onMounted, ref, shallowRef } from 'vue'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheTopTraders',
  components: { Icon, TheMasterPositions, TheRankNumber, ColorText, Pic, Nullable, Holder },
  props: {},
  setup () {
    const traders = shallowRef<Master[] | null>(null)
    const activeIndex = shallowRef(0)
    const tabImg = ref<HTMLElement>()
    const opened = shallowRef(true)

    onMounted(() => {
      const localTab = localGet(keymap.cup.subTab)
      if (tabImg.value) {
        tabImg.value.style.transform = 'rotateY(' + Number(localTab) * 180 + 'deg)'
      }
      activeIndex.value = Number(localTab)
      read()
    })

    const prompt = promptOpenOperation(isPublic => opened.value = isPublic)

    const read = () => readMasters({ type: activeIndex.value }).then(resp => traders.value = resp)

    const change = (index: number) => {
      if (index === activeIndex.value) {
        return false
      }
      activeIndex.value = index
      localSet(keymap.cup.subTab, String(index))

      read()

      if (tabImg.value) {
        tabImg.value.style.transform = 'rotateY(' + index * 180 + 'deg)'
      }
    }

    return {
      traders,
      activeIndex,
      tabImg,
      opened,
      prompt,
      change,
      cupDateFormat,
    }
  },
})
