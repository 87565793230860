
import ThePrizeCategory from '@/modules/weTradeCup/components/ThePrizeCategory.vue'
import TheTopTraders from '@/modules/weTradeCup/components/TheTopTraders.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import { defineComponent, shallowRef } from 'vue'
import { translate } from '@/i18n'
import { keymap } from '@/config'
import { localGet, localRemove } from 'essential/store/localStore'
import { onBeforeRouteLeave } from 'vue-router'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'WeTradeCup',
  components: { TheTopTraders, ThePrizeCategory, PageWithHeader, TheCupTabSwitch, Icon },
  props: {},
  setup () {
    const localTab = localGet(keymap.cup.mainTab)
    const usePrizeView = shallowRef(!Number(localTab))

    onBeforeRouteLeave((to) => {
      if (to.name !== 'symbol') {
        localRemove(keymap.cup.mainTab)
        localRemove(keymap.cup.subTab)
      }
    })

    return {
      usePrizeView,
      translate,
    }
  },
})
